import styled from "styled-components";

const Container = styled.div`
  margin: 0px 60px;
  @media (max-width: 720px) {
    margin: 0px 10px;
    height: calc(100vh - 191px);
  }
`;

const TipoArquivo = styled.div`
  background: #eaeaea;
  border-radius: 13px;
  border-radius: 20px;
  padding: 20px;
  color: #6e6e6e;
  margin-bottom: 20px;
`;

const ContainerLoading = styled.div`
box-sizing: border-box;
background: #E7EAFF;
border: 1px dashed #5E74F8;
border-radius: 25px;
display: flex;
align-items: center;
padding: 20px;
}
`;

const Icon = styled.img`
  width: 100px;
  margin-right: 10px;
`;

const Content = styled.div`
  width: 100%;
`;

const ContainerButton = styled.div`
  display: flex;
  justify-content: end;
`;

const Titulo = styled.div`
font-weight: 700;
font-size: 18px;

`;

export {
  Container,
  TipoArquivo,
  ContainerLoading,
  Content,
  Icon,
  ContainerButton,
  Titulo
};
