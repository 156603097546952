import React from "react";
import * as S from "./ComfirmacaoStyled";

const Comfirmacao = () => {
  return (
    <>
      <S.Titulo>Resumo da inscrição</S.Titulo>
      <S.Container>
        <S.ContainerImg>
          <S.Img src="https://imgur.com/Oqra4jw.png" />
        </S.ContainerImg>
        <div>
          <S.SubTitulo>Sua solicitação foi confirmada com sucesso!</S.SubTitulo>
          <S.Texto>Em breve enviaremos informações sobre</S.Texto>
          <S.Texto>essa solicitação no seu e-mail.</S.Texto>
        </div>
      </S.Container>
    </>
  );
};

export default Comfirmacao;
