import {useEffect, useRef} from "react";

import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import { useField } from "@unform/core";
import { OutlinedInput, Select } from "@material-ui/core";
import { useIMask } from 'react-imask';



function InputCadastro({ name, ref, children, ...rest }) {
  const inputRef = useRef(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: (ref) => {
        return ref.current.value;
      },
      setValue: (ref, value) => {
        ref.current.value = value;
      },
      clearValue: (ref) => {
        ref.current.value = "";
      },
    });
  }, [fieldName, registerField]);

  return (
    <>
      <TextField
        inputRef={inputRef}
        {...rest}
        size="small"
        variant="outlined"
      />
    </>
  );
  
}

function SelectCadastro({ name, children, required, label, className, ...rest }) {
  const inputRef = useRef(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);

  useEffect(() => {
    console.log(rest)
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: (ref) => {
        return ref.current.value;
      },
      setValue: (ref, value) => {
        ref.current.value = value;
        console.log('value',value)
      },
      clearValue: (ref) => {
        ref.current.value = "";
      },
    });
  }, [fieldName, registerField]);

  return (
    <FormControl variant="outlined" size="small" className={className} required={required}>
      <InputLabel>{label}</InputLabel>
      <Select
        inputRef={inputRef}
        label={label}
        {...rest} 
        native
      >
          <option aria-label="None" value="" ></option>
        {children}
      </Select>
    </FormControl>
  );
  
}

function InputMask({ name, children, required, label, className, mask, ...rest }) {
  const {ref} = useIMask({mask: mask})
  const { fieldName, defaultValue, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: ref,
      getValue: (ref) => {
        return ref.current.value;
      },
      setValue: (ref, value) => {
        ref.current.value = value;
      },
      clearValue: (ref) => {
        ref.current.value = "";
      },
    });
  }, [fieldName, registerField]);

  return (
    <FormControl variant="outlined" size="small" className={className} required={required}>
      <InputLabel>{label}</InputLabel>
      <OutlinedInput label={label} inputRef={ref} {...rest}/>
    </FormControl>
  );
  
}

export {InputCadastro, SelectCadastro, InputMask};
