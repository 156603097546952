import React from "react";
import * as S from "./MenuStyled";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

function Menu() {
  return (
    <S.Menu>
      <S.ContainerLogo>
        <S.Logo src="https://imgur.com/0Vc2cbI.png" />
      </S.ContainerLogo>
      <div>
        <S.Link href="/">
          <S.Button>
            <ArrowBackIcon fontSize="medium" style={{ margin: "0 10px" }} />
          Voltar
          </S.Button>
        </S.Link>
      </div>
    </S.Menu>
  );
}

export default Menu;
