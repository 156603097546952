import styled from "styled-components";
const Card = styled.div`
  background-image: linear-gradient(#77cd94 45%, #ededed 45%);
  height: 320px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
`;
const ContainerFoto = styled.div`
  margin: auto;
  padding: 0 90px;
`;
const Foto = styled.img`
  border-radius: 50%;
  width: 100%;
  max-width: 130px;
  height: 130px;
  background: ${(props) => props.background};
`;

const ContainerButton = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
`;
const Button = styled.button`
  background: #fff;
  background: #ffffff;
  box-shadow: 0px 2.33687px 2.33687px rgba(0, 0, 0, 0.25);
  border-radius: 2.92109px;
  width: 100%;
  border-style: none;
  padding: 15px 0px;
  max-width: 260px;
  font-size: 18px;
`;

export { Card, Foto, Button, ContainerFoto, ContainerButton };
