import styled from "styled-components";

const Container = styled.div`
  display: flex;
  @media (max-width: 720px) {
    align-items: center;
    justify-content: space-around;
  }
`;

const ContainerButton = styled.div`
  display: none;
  @media (max-width: 720px) {
    display: flex;
  }
`;

const Titulo = styled.h1`
  font-size: 40px;
  margin: 60px 0px 60px 60px;
  color: #5e74f8;
  @media (max-width: 720px) {
    font-size: 30px;
    margin: 15px 0px 15px 30px;
  }
`;

const ContainerStepper = styled.div`
  margin: 0 60px;
  @media (max-width: 720px) {
    margin: 0 10px;
  }
`;

export { Container, Titulo, ContainerStepper, ContainerButton };
