import styled from "styled-components";

const Titulo = styled.div`
  color: #5e74f8;
  font-size: 34px;
  font-weight: 900;
  margin: 60px 0px 20px 60px;
  @media (max-width: 720px) {
    margin: 15px 0px 15px 10px;
  }
`;

const Container = styled.div`
  margin: 0px 60px;
  background: #ffffff;
  border-radius: 13px;
  border-radius: 20px;
  padding: 40px 20px 80px 20px;
  margin-bottom: 20px;
  text-align: center;
  @media (max-width: 720px) {
    margin: 0px 10px;
  }
`;

const ContainerImg = styled.div``;

const Img = styled.img`
  width: 230px;
  @media (max-width: 720px) {
    width: 200px;
`;

const SubTitulo = styled.h2`
  color: #5e74f8;
  font-size: 28px;
  margin-bottom: 20px;
`;

const Texto = styled.div`
  color: #3d3d3d;
  font-size: 24px;
`;

export { Container, ContainerImg, Img, SubTitulo, Titulo, Texto };
